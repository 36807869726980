import './bootstrap';

import { registerLogjamTracking } from '@xing-com/crate-core-fetch/src/browser/logjam';
import { extractRoutes } from '@xing-com/crate-runtime';
import type { GlobalScope } from '@xing-com/crate-xinglet/internal';

import { setupDataDog } from './datadog';
import { loadManifest } from './load-manifest';
import { render } from './render';

declare const globalThis: GlobalScope;

(async () => {
  const {
    startXinglets = [],
    dataDog,
    serverData = {},
    ...config
  } = globalThis.crate;

  const captureException = setupDataDog(dataDog);

  try {
    const manifestMap = await loadManifest(config);
    registerLogjamTracking(config, extractRoutes(manifestMap));

    await render({
      captureException,
      config,
      manifestMap,
      startXinglets,
      serverData,
    });
  } catch (error) {
    captureException(error);
    throw error;
  }
})().catch((error) => {
  console.error(error);
  const pre = document.createElement('pre');
  const text = document.createTextNode(error.stack ?? error);
  pre.appendChild(text);
  document.getElementById('app')?.replaceWith(pre);
});
